import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeading from "@components/page-heading"
import HeadingBg from "@images/slider-img2.jpg"

export default function ThankYou() {
  return (
    <Layout>
      <SEO />
      <PageHeading
        heading="We have recieved your sign-up details successfully. We'll reach out to you as soon as possible!"
        // subheading="We have recieved your sign-up details successfully. We'll reach out to you as soon as possible!"
        BgImg={HeadingBg}
        BtnLink="/"
        BtnLabel="BACK TO HOME"
        className="pseudo-none"
      />
    </Layout>
  )
}
